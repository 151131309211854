import { HTTP } from '../../axios/axios'
import sanitizeHtml from 'sanitize-html'

async function getAll (lang) {
  try {
    const res = await HTTP.get('/leaders', { headers: { 'accept-language': lang } })
    return res.data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (leader, lang) {
  leader.biography = sanitizeHtml(leader.biography)
  leader.lang = lang
  return await HTTP.post('/leaders', leader)
}
async function update (leader, id) {
  leader.biography = sanitizeHtml(leader.biography)
  return await HTTP.patch('/leaders/' + id, leader)
}
async function deleteOne (leader) {
  return await HTTP.delete('/leaders/' + leader._id)
}

async function deleteMany (arr) {
  return await HTTP.post('/leaders/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
